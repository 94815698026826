import { Text } from '@dropbox/dig-components/typography';
import { Card } from '@dropbox/dig-components/card';
import { Box } from '@dropbox/dig-foundations';
import React from 'react';
import { useSignerContext } from 'signer-app/signer-experience/signer-experience';
import { Button } from '@dropbox/dig-components/buttons';
import { FormattedMessage } from 'react-intl';
import {
  FormHelperText,
  FormLabel,
  FormRow,
} from '@dropbox/dig-components/form_row';

export function AttachmentsPage() {
  const { attachmentSlots, uploadAttachment } = useSignerContext();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      margin="auto"
    >
      <Box>
        <Box
          display="flex"
          flexDirection="column"
          marginTop="Macro Medium"
          paddingBottom="Macro Small"
        >
          <Text size="large" variant="label">
            Attachments
          </Text>
          <Text color="subtle">The sender has requested 1 attachment.</Text>
        </Box>

        {attachmentSlots.map((attachment) => (
          <Box key={attachment.id} marginBottom={'Macro Small'}>
            <Card>
              <Card.Content>
                <Box display="flex" flexDirection={'column'}>
                  <FormRow>
                    <FormLabel htmlFor={attachment.id}>
                      {attachment.name}
                    </FormLabel>
                    <FormHelperText id="instructions">
                      instructions: {attachment.instructions}
                    </FormHelperText>

                    <Box display="none">
                      <input
                        type="file"
                        id={`file-${attachment.id}`}
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file) {
                            uploadAttachment({
                              attachmentSlotId: attachment.id,
                              file,
                            });
                          }
                        }}
                      />
                    </Box>
                    <FormHelperText id="instructions">
                      <FormattedMessage
                        id="signer.attachment.accepted_file_formats"
                        description="text in signer flow of attachment page, tells signer the format and size of the file to upload"
                        defaultMessage="Acceptable file formats: .png, .pdf, .gif, .tiff * Max File Size: 3MB."
                      />
                    </FormHelperText>
                  </FormRow>
                  <Button
                    type="button"
                    variant="primary"
                    onClick={() =>
                      document.getElementById(`file-${attachment.id}`)?.click()
                    }
                  >
                    Upload
                  </Button>
                  <Box marginTop="Micro Medium">
                    <Text>Uploaded file: {attachment.fileName}</Text>
                  </Box>
                </Box>
              </Card.Content>
            </Card>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
