/* eslint-disable no-restricted-syntax */
import { Box } from '@dropbox/dig-foundations';
import React from 'react';
import styles from 'signer-app/signer-experience/footer.module.css';
import { GlyphLogo, SignGlyph } from '@dropbox/dig-logos';
import { FormattedMessage } from 'react-intl';
import { Menu } from '@dropbox/dig-components/menu';
import { Button } from '@dropbox/dig-components/buttons';
import { atom, useAtomValue } from 'jotai';

import linkedIn from 'signer-app/signer-experience/linkedin@2x.png';
import fb from 'signer-app/signer-experience/fb@2x.png';
import twitter from 'signer-app/signer-experience/twitter@2x.png';

export const footerAtom = atom({
  privacyURL: '',
  termsURL: '',
});

const socials = [
  {
    name: 'linkedin',
    url: 'https://www.linkedin.com/company/dropboxsign',
    logo: linkedIn,
  },
  {
    name: 'fb',
    url: 'https://www.facebook.com/dropboxsign',
    logo: fb,
  },
  {
    name: 'twitter',
    url: 'https://twitter.com/DropboxSign',
    logo: twitter,
  },
];

function Socials() {
  return (
    <Box className={styles.socials}>
      {socials.map(({ name, url, logo }) => (
        <div key={`social-${name}`}>
          <a href={url} target="_blank" rel="noopener noreferrer">
            <img className={styles.img} src={logo} />
          </a>
        </div>
      ))}
    </Box>
  );
}

export function Footer() {
  const wwwDomain = React.useMemo(
    () => String(location.hostname).replace('app.', 'www.'),
    [],
  );
  const www = (path: string) => `https://${wwwDomain}${path}`;
  const { privacyURL, termsURL } = useAtomValue(footerAtom);

  return (
    <Box className={styles.anchor}>
      <Box
        className={styles.footer}
        borderTop="Solid"
        borderColor="Border Subtle"
        display="flex"
        flexDirection="row"
        alignItems={'center'}
      >
        <GlyphLogo src={SignGlyph} size={56} />
        <Button
          target="_blank"
          href="/info/pricing"
          rel="noreferrer"
          variant="transparent"
        >
          <FormattedMessage
            id="3317ef164ccf7a78c5837cf20664289c6b35175cde2fc51712d6ec6527ecb11c"
            description="webapp, homepage footer pricing link"
            defaultMessage="Pricing"
          />
        </Button>
        <Menu.Wrapper>
          {({ getContentProps, getTriggerProps }) => (
            <>
              <Button {...getTriggerProps()} variant="transparent">
                <FormattedMessage
                  id="5a29b301df66de0f7d51d75f330c1503b3d85c59376a9e7aecf3a2eaf4257e08"
                  description="webapp, homepage footer products link"
                  defaultMessage="Products"
                />
              </Button>
              <Menu.Content {...getContentProps()}>
                <Menu.LinkItem href={www('/gmail')}>
                  <FormattedMessage
                    id="8f27a5196a8926066e7e6818ab67ee5a8f4724b0ffa37a8b74bee1d426326f1f"
                    description="webapp, homepage footer product link dd gmail"
                    defaultMessage="Gmail"
                  />
                </Menu.LinkItem>
                <Menu.LinkItem href={www('/googledocs')}>
                  <FormattedMessage
                    id="af5d4cd311d5d06d2f5a19aeeb8fcf0bac843018b6792cfa01cd45117aad249c"
                    description="webapp, homepage footer product link dd google docs"
                    defaultMessage="Google Docs"
                  />
                </Menu.LinkItem>
                <Menu.LinkItem href={www('/googledrive')}>
                  <FormattedMessage
                    id="11e48d8bf70e540f67ef9f0a20b82b45cd42314f50c77cf83bb1f67baa3d3cbb"
                    description="webapp, homepage footer product link dd google drive"
                    defaultMessage="Google Drive"
                  />
                </Menu.LinkItem>
                <Menu.LinkItem href={www('/features/mobile')}>
                  <FormattedMessage
                    id="31accd8fbfed4f75a4e952e57517542aa3af93ce0d459007f5bca7f9aef43270"
                    description="webapp, homepage footer product link dd mobile"
                    defaultMessage="Mobile"
                  />
                </Menu.LinkItem>
                <Menu.LinkItem
                  href={'home/bridge/to/F'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dropbox Fax
                </Menu.LinkItem>
              </Menu.Content>
            </>
          )}
        </Menu.Wrapper>
        <Menu.Wrapper>
          {({ getContentProps, getTriggerProps }) => (
            <>
              <Button {...getTriggerProps()} variant="transparent">
                <FormattedMessage
                  id="8eb17eb3162f408672e236ac0da3b869230e84eb44c4b21d59614467f57ab776"
                  description="webapp, homepage footer fine print link"
                  defaultMessage="Fine print"
                />
              </Button>
              <Menu.Content {...getContentProps()}>
                <Menu.LinkItem href={www('/info/security')}>
                  <FormattedMessage
                    id="e3769bb8a2a765ea75611e81fd13a02f3147df78502f108899ab985a8d6ce685"
                    description="webapp, homepage footer fine print link dd security"
                    defaultMessage="Security"
                  />
                </Menu.LinkItem>
                <Menu.LinkItem key={1} href={www('/info/legal')}>
                  <FormattedMessage
                    id="0fc4c5ecbf16a8a6ed0273ceef57c88143c12f7655208c410f033b76f48cb86e"
                    description="webapp, homepage footer fine print link dd legal"
                    defaultMessage="Legal"
                  />
                </Menu.LinkItem>
                <Menu.LinkItem href={privacyURL}>
                  <FormattedMessage
                    id="c97e74a315ce77cbae48dad45d307c14fe4f78e448797f72109fe970692650b7"
                    description="webapp, homepage footer fine print link dd privacy"
                    defaultMessage="Privacy"
                  />
                </Menu.LinkItem>
                <Menu.LinkItem href={termsURL}>
                  <FormattedMessage
                    id="0f09693d870d644637b68bc18194e95c3ad89f6f36e6f35764b97488f410c79a"
                    description="webapp, homepage footer fine print link dd terms"
                    defaultMessage="Terms"
                  />
                </Menu.LinkItem>
              </Menu.Content>
            </>
          )}
        </Menu.Wrapper>
        <Button
          target="_blank"
          href={'/home/help'}
          rel="noreferrer"
          variant="transparent"
        >
          <FormattedMessage
            id="e969fd592e69959f25c24fb9a9033c735a6a77eb7f018085d6d7ff2bd9f85fdb"
            description="webapp, homepage footer help link"
            defaultMessage="Help"
          />
        </Button>

        <Socials />
      </Box>
    </Box>
  );
}
