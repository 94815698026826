import React from 'react';
import { useEventListener } from 'signer-app/utils/use-event-listener';
import { usePreviousValue } from 'signer-app/utils/use-previous-value';
import { usePreventNativeZoom } from 'signer-app/utils/use-prevent-native-zoom';
import { Field, Page } from 'signer-app/types/editor-types';
import PositionContext from 'signer-app/signature-request/position-context';
import { trackHeapCustomEvent } from 'signer-app/utils/heap';
import {
  ORIGIN_VIEWPORT,
  ORIGIN_PAGE,
  ORIGIN_PAGE_CONTAINER,
  IS_MAC,
  FIT_WIDTH,
} from 'signer-app/signature-request/constants';

const MAX_ZOOM = 3;
const MIN_ZOOM = 0.25;
const OUT_ZOOM = 1.05;
const IN_ZOOM = 0.95;

type Position = {
  pageIndex: number;
  x: number;
  y: number;
  width: number;
  height: number;
  documentId: string;
};
export type DocumentAddress = Position;

// To manage zoom-independent CSS, these need to return measurements as a %.
export type ScreenPosition =
  | Position
  | {
      pageIndex: number;
      x: string;
      y: string;
      width: string;
      height: string;
      documentId: string;
    };

export type Origin =
  | typeof ORIGIN_VIEWPORT
  | typeof ORIGIN_PAGE
  | typeof ORIGIN_PAGE_CONTAINER;

interface Rect {
  top: number;
  left: number;
  width: number;
  height: number;
}

// I'm putting everything related to zoom in its own context to minimize the
// number of components that have to render when it changes. This separates
// components that need data vs layout information.
export interface ZoomContextShape {
  zoom: number;
  setZoom: (newZoom: number, focus?: Focus | null) => void;
  // this is NOT the same as zoom if defaultPageWidth is FIT_WIDTH (Signer/Overlay)
  textScale: number;
  fitWidthScale: number;
  pxMaxPageWidth: number;
  zoomIntoField: (fieldData: object) => void;
  scaleZoom: (eventType: 'pinchin' | 'pinchout') => void;
  toScreenCoords(address: Position, origin: Origin): ScreenPosition;
  // fromScreenCoords doesn't use ScreenPosition, because it uses pixel
  // measurmeents, usually obtained from getBoundingClientRect
  fromScreenCoords(address: Position, origin: Origin): Position;
  yToPageIndex(y: number): number;
  getPageBoundingRect(
    pageIndex: number,
    origin: Origin,
    localZoom?: number,
  ): Rect;
}

export interface SignatureRequestContextShape {
  pageContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  setPageContainerRef(ref: HTMLDivElement): void;
  onScroll(element: React.UIEvent<HTMLElement>): void;
  topGap: boolean;
  pageGap: number;
  pages: Page[];
  selectedFieldIds: Array<Field['id']>;
  documentPreview: boolean;
  isOverlay: boolean;
  inlinePreviewMode: boolean;
  lastUpdatedFieldId?: Field['id'];
}

export const signatureRequestContext = React.createContext(
  {} as SignatureRequestContextShape,
);

export const useSignatureRequestContext = () =>
  React.useContext(signatureRequestContext);

export const fieldsContext = React.createContext<Field[]>([]);
export const useFieldsContext = () => React.useContext(fieldsContext);

export const zoomContext = React.createContext<ZoomContextShape>({
  zoom: 1,
  fitWidthScale: 1,
  textScale: 1,
  pxMaxPageWidth: 680,
  setZoom() {},
  zoomIntoField() {},
  scaleZoom() {},
  fromScreenCoords: () => ({
    pageIndex: 0,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    documentId: '',
  }),
  toScreenCoords: () => ({
    pageIndex: 0,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    documentId: '',
  }),
  yToPageIndex: () => 0,
  getPageBoundingRect: () => ({ top: 0, left: 0, width: 0, height: 0 }),
});
export const useZoomContext = () => React.useContext(zoomContext);

export class ContextCapture extends React.Component<
  React.PropsWithChildren<object>
> {
  // This is a hack to let me grab the context in tests because functional
  // components don't have an instance and signatureRequestContext.Provider
  // doesn't seem to have an instance either
  static contextType = zoomContext;

  render() {
    return this.props.children;
  }
}

interface Focus {
  x: number;
  y: number;
  isCenter?: boolean;
  centerVertically?: boolean;
}
const getCenter = (): Focus => {
  return {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
    isCenter: true,
  };
};

type Zoom = number | typeof FIT_WIDTH;

export const clampZoom = (zoom: number) => {
  if (zoom < MIN_ZOOM) {
    return MIN_ZOOM;
  }

  if (zoom > MAX_ZOOM) {
    return MAX_ZOOM;
  }
  return zoom;
};
type ZoomAddress = Position & {
  focus?: Focus;
  centerVertically?: boolean;
};

type SignatureRequestProps = React.PropsWithChildren<{
  dpi?: number;
  pageGap?: number;
  topGap?: boolean;
  containerWidth?: number | null;
  fields: Field[];
  selectedFieldIds?: Field['id'][];
  pages: Page[];
  // this isn't exactly a Zoom, but it shares FIT_WIDTH
  defaultPageWidth?: Zoom;
  defaultZoom?: Zoom;
  documentPreview?: boolean;
  isOverlay?: boolean;
  inlinePreviewMode?: boolean;
  lastUpdatedFieldId?: Field['id'];
}>;

const emptyFields: string[] = [];
const _SignatureRequestContext: React.ForwardRefRenderFunction<
  any,
  SignatureRequestProps
> = (
  {
    dpi = 80,
    pageGap = 10,
    topGap = false,
    containerWidth: forcedContainerWidth = null,
    children,
    fields,
    selectedFieldIds = emptyFields,
    pages,
    defaultPageWidth = FIT_WIDTH,
    defaultZoom = 1,
    documentPreview = false,
    isOverlay = false,
    inlinePreviewMode = false,
    lastUpdatedFieldId = undefined,
  }: SignatureRequestProps,
  ref,
) => {
  const pageContainerRef = React.useRef<HTMLDivElement>(null);
  const [state, setState] = React.useState<{
    zoom: Zoom;
    zoomAddress?: ZoomAddress | null;
    zoomFocusBefore?: ZoomAddress | null;
    focus?: Focus | null;
  }>({
    zoom: defaultZoom,
  });
  const [dimensions, setDimensions] = React.useState<{
    top: number;
    left: number;
    width: number;
  }>();
  const [scrollCoords, setScrollCoords] = React.useState<{
    scrollTop: number;
    scrollLeft: number;
  }>({
    scrollTop: 0,
    scrollLeft: 0,
  });

  usePreventNativeZoom();

  const observerRef = React.useRef<ResizeObserver | null>(null);
  const setPageContainerRef = React.useCallback((current: HTMLElement) => {
    if (current === pageContainerRef.current) {
      return;
    } else if (pageContainerRef.current) {
      observerRef.current?.unobserve(pageContainerRef.current);
    }
    // @ts-ignore
    pageContainerRef.current = current;

    // JSDom doesn't implement getBoundingClientRect or provide HTMLElement so return early
    if (!current) {
      return;
    }
    const observer = new ResizeObserver(() => {
      const boundingClientRect = current.getBoundingClientRect();
      setDimensions({
        top: boundingClientRect.top,
        left: boundingClientRect.left,
        width: boundingClientRect.width,
      });
      setScrollCoords({
        scrollTop: current.scrollTop,
        scrollLeft: current.scrollLeft,
      });
    });
    observerRef.current = observer;
    observer.observe(current);
  }, []);

  const onScroll = React.useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      const target = e.currentTarget;

      setScrollCoords({
        scrollTop: target.scrollTop,
        scrollLeft: target.scrollLeft,
      });
    },
    [setScrollCoords],
  );

  const widestPageIndex = React.useMemo(
    () =>
      pages
        .map((p, pageIndex) => ({ ...p, pageIndex }))
        .reduce((a, b) => (b.width > a.width ? b : a), {
          pageIndex: 0,
          width: 0,
        }).pageIndex,
    [pages],
  );

  const measurePageContainer = React.useCallback(() => {
    if (dimensions && typeof jest === 'undefined') {
      return {
        scrollTop: scrollCoords.scrollTop,
        scrollLeft: scrollCoords.scrollLeft,
        containerTop: dimensions.top,
        containerLeft: dimensions.left,
        // forcedContainerWidth only exists so that the Overlay can specify a
        // width where everything else measures the available screen space. The
        // 1024 acts as a fallback for tests where JSDom doesn't do any layout,
        // so there is nothing to measure even when page container exists.
        containerWidth: forcedContainerWidth || dimensions.width,
      };
    }

    return {
      scrollTop: 0,
      scrollLeft: 0,
      // Assuming there is a toolbar
      containerTop: 56,
      containerLeft: 0,
      containerWidth: forcedContainerWidth || 1024,
    };
  }, [forcedContainerWidth, dimensions, scrollCoords]);

  // This needs to be run on render so that `getFitScale` and `pxMaxPageWidth`
  // are forced to update if the width changes. In a previous version they would
  // call `measurePageContainer` and nothing was forcing them to update.
  const { containerWidth } = measurePageContainer();

  const getFitScale = React.useCallback(
    (scale) => {
      const widestPage = pages[widestPageIndex] || { width: 660 };

      return containerWidth / widestPage.width / scale;
    },
    [containerWidth, pages, widestPageIndex],
  );

  const US_LETTER_PORTRAIT_WIDTH = 8.5 * dpi;
  const scale = React.useMemo(() => {
    if (defaultPageWidth === FIT_WIDTH) {
      return getFitScale(1);
    }

    return defaultPageWidth / US_LETTER_PORTRAIT_WIDTH;
  }, [US_LETTER_PORTRAIT_WIDTH, defaultPageWidth, getFitScale]);
  // containerWidth is used by Overlay. It allows us to specify a width instead
  // of measuring pageContainerRef
  const fitWidthScale =
    pageContainerRef.current != null || forcedContainerWidth != null
      ? getFitScale(scale)
      : 1; // If this is 0 it breaks tests
  const numericZoom = state.zoom === FIT_WIDTH ? fitWidthScale : state.zoom;

  // I'd like to calculate this in `document.jsx`, but it doesn't have access to
  // `dpi` and I don't want to add that to context. I considered attaching `dpi`
  // to each page, but then realized I don't think we can handle mixed DPIs
  // across multiple pages. None of this really matters today because we're
  // stuck with 80dpi until the new Overlay is ready, and we change whatever
  // converts documents, and update the overlay data to specify a new DPI. `dpi`
  // is still around because we thought we were going to change the default  DPI
  // at the start of the Editor project.
  const pxMaxPageWidth = React.useMemo(() => {
    let targetWidth: number;
    if (defaultPageWidth === FIT_WIDTH) {
      return containerWidth;
    } else {
      targetWidth = defaultPageWidth;
    }

    // Regardless of page DPI, we want US Letter pages to be 680px wide @ 100%
    const usLetterScale = targetWidth / US_LETTER_PORTRAIT_WIDTH;
    const maxWidth = Math.max(...pages.map((p) => p.width));
    return maxWidth * usLetterScale;
  }, [US_LETTER_PORTRAIT_WIDTH, containerWidth, defaultPageWidth, pages]);

  const getRenderScale = React.useCallback(
    (localZoom = numericZoom) => {
      if (defaultPageWidth === FIT_WIDTH) {
        return getFitScale(1) * numericZoom;
      }
      return localZoom * scale;
    },
    [defaultPageWidth, numericZoom, scale, getFitScale],
  );

  const _pageSizeHelper = React.useCallback(
    (page, localZoom = numericZoom) => {
      const aspectRatio = page.height / page.width;

      const width = page.width * getRenderScale(localZoom);

      return {
        width,
        height: width * aspectRatio,
      };
    },
    [getRenderScale, numericZoom],
  );

  /**
   * This used to calculate a number based on the assumption that the pages are
   * always US Letter. That calculation doesn't really need to happen and having it
   * ends up causing problems with other aspect ratios.
   */
  const textScale = React.useMemo(() => {
    return getRenderScale();
  }, [getRenderScale]);

  const getPageSize = React.useCallback(
    (pageIndex, localZoom = numericZoom) =>
      _pageSizeHelper(pages[pageIndex], localZoom),
    [_pageSizeHelper, numericZoom, pages],
  );

  type PageBoundingRectCache = {
    pages: typeof pages;
    key: string;
    shift: number;
    [index: number]: {
      top: number;
      left: number;
      width: number;
      height: number;
    };
  };
  const pbrCache = React.useRef<PageBoundingRectCache>({
    pages,
    key: '',
    shift: 0,
  });
  // Reset the cache if the pages change
  if (pbrCache.current.pages !== pages) {
    pbrCache.current = { pages, key: '', shift: 0 };
  }

  // When called with ORIGIN_VIEWPORT this returns the same thing as calling
  // getBoundingClientRect() on the DOM node.
  const getPageBoundingRect = React.useCallback(
    (
      pageIndex: number,
      origin: Origin,
      // usePinchAndZoom needs to ask for hypothetical page coordinates if the
      // zoom were something else.
      localZoom = numericZoom,
    ): Rect => {
      if (!origin) {
        throw new Error('Missing Origin');
      }

      // WARNING: This CANNOT use cached values from when context last rendered. I
      // tried to remove `measurePageContainer` and just call it on render, but
      // that captures the scroll position. This function will be called when you
      // click to place a field, and `measurePageContainer` MUST run at that time.
      const {
        containerWidth,
        containerTop,
        scrollTop,
        containerLeft,
        scrollLeft,
      } = measurePageContainer();
      const { width, height } = getPageSize(pageIndex, localZoom);

      if (origin === ORIGIN_PAGE) {
        return {
          top: 0,
          left: 0,
          width,
          height,
        };
      }

      // If the key changes the cache needs to reset
      const key = `${containerWidth}_${localZoom}`;
      if (pbrCache.current.key !== key) {
        const { width } = getPageSize(widestPageIndex, localZoom);
        const shift = (containerWidth - width) / 2;

        pbrCache.current = {
          pages,
          key,
          shift: 0 - Math.min(0, shift),
        };
      }

      if (pbrCache.current[pageIndex] == null) {
        // ORIGIN_PAGE
        const left = (containerWidth - width) / 2 + pbrCache.current.shift;
        let top: number;

        pbrCache.current[pageIndex] = {
          get top() {
            if (top == null) {
              top = topGap ? pageGap : 0;
              if (pageIndex > 0) {
                const tmp = getPageBoundingRect(
                  pageIndex - 1,
                  ORIGIN_PAGE_CONTAINER,
                  localZoom,
                );
                top = tmp.top + tmp.height + pageGap;
                // If we're creating a top gap before the first page, all gaps
                // between pages are also doubled.
                if (topGap) {
                  top += pageGap;
                }
              }
            }
            return top;
          },
          left,
          width,
          height,
        };
      }

      const originRect = pbrCache.current[pageIndex];
      if (origin === ORIGIN_VIEWPORT) {
        return {
          ...originRect,
          left: originRect.left + containerLeft - scrollLeft,
          top: originRect.top + containerTop - scrollTop,
        };
      }

      return pbrCache.current[pageIndex];
    },
    [
      measurePageContainer,
      getPageSize,
      numericZoom,
      widestPageIndex,
      pages,
      topGap,
      pageGap,
    ],
  );

  const yToPageIndex = React.useCallback(
    (y) =>
      pages.findIndex((_, index) => {
        const { top, height } = getPageBoundingRect(index, ORIGIN_VIEWPORT);
        return top <= y && y <= top + height + pageGap;
      }),
    [getPageBoundingRect, pageGap, pages],
  );

  const toScreenCoords = React.useCallback(
    (address, origin) => {
      const { pageIndex } = address;
      const { top, left } = getPageBoundingRect(pageIndex, origin);

      const renderScale = getRenderScale();

      const x = address.x * renderScale + left;
      const y = address.y * renderScale + top;
      const width = address.width * renderScale;
      const height = address.height * renderScale;
      const documentId = pages[pageIndex].documentId;

      // :'( .toFixed returns a string, so it needs to be converted back to a number
      const toFixed = (n: number) => Number(n.toFixed(4));

      return {
        pageIndex,
        x: toFixed(x),
        y: toFixed(y),
        width: toFixed(width),
        height: toFixed(height),
        documentId,
      };
    },
    [getPageBoundingRect, getRenderScale, pages],
  );

  const fromScreenCoords = React.useCallback(
    (screenAddress, origin) => {
      let pageIndex = yToPageIndex(screenAddress.y + screenAddress.height / 2);
      if (pageIndex === -1) {
        pageIndex = screenAddress.pageIndex;
      }

      const { top, left } = getPageBoundingRect(pageIndex, origin);
      const renderScale = 1 / getRenderScale();

      let x = screenAddress.x - left;
      let y = screenAddress.y - top;
      x = Math.round(x * renderScale);
      y = Math.round(y * renderScale);
      const width = Math.round(screenAddress.width * renderScale);
      const height = Math.round(screenAddress.height * renderScale);
      const documentId = pages[pageIndex].documentId;

      return {
        pageIndex,
        x,
        y,
        width,
        height,
        documentId,
      };
    },
    [getPageBoundingRect, getRenderScale, pages, yToPageIndex],
  );

  const setZoom = React.useCallback(
    (
      newZoom: Zoom,
      // `focus` is a point on the document that should NOT move when the zoom
      // changes. If none is supplied, this grabs the coordinate in the center of
      // the screen, and converts it to a document address. After zoom is changed
      // and it re-renders, that document address is converted back to a screen
      // coordinate, then scrollLeft/scrollRight are adjusted to put that document
      // address back where it was on the screen before the zoom. When `null` is
      // suplied, the whole process is skipped.
      focus: Focus | null = getCenter(),
    ) => {
      trackHeapCustomEvent('clicked_zoom');
      let zoom = newZoom;

      if (zoom === numericZoom) {
        return;
      }

      // @ts-ignore zoom might be a symbol
      zoom = clampZoom(zoom);

      if (focus === null) {
        setState((state) => ({
          ...state,
          zoom,
          zoomFocusBefore: null,
          zoomAddress: null,
          focus,
        }));
        return;
      }

      // If the mouse hasn't moved from the last zoom, then just keep zoming in
      // on the original address. componentDidUpdate is going to scroll the
      // container to place that address back under the mouse, but the scroll
      // event fires faster than the scroll correction can happen. So if you
      // keep getting a new address from the mouse, the zoomAddress will keep
      // shifting.
      const sameFocus =
        state.zoomAddress != null &&
        !focus.isCenter &&
        Math.abs(state.zoomAddress.focus!.x - focus.x) < 5 &&
        Math.abs(state.zoomAddress.focus!.y - focus.y) < 5;

      // Find the address of where the focus is right now. After zooming that
      // address will be found and scrolled back to where it is on the screen.
      const zoomAddress: ZoomAddress = sameFocus
        ? state.zoomAddress!
        : fromScreenCoords(
            {
              ...focus,
              // It doesn't matter what page you're zooming in on, we can always use
              // an address based on the first page. because this needs to find the
              // distance that the target address moved on screen.
              pageIndex: 0,
              width: 0,
              height: 0,
            },
            ORIGIN_VIEWPORT,
          );
      // Also store the oringal focus coordinates
      zoomAddress.focus = focus;

      const zoomFocusBefore = toScreenCoords(zoomAddress, ORIGIN_VIEWPORT);

      setState((state) => ({
        ...state,
        zoom,
        zoomFocusBefore,
        zoomAddress,
        focus,
      }));
    },
    [
      fromScreenCoords,
      numericZoom,
      setState,
      state.zoomAddress,
      toScreenCoords,
    ],
  );

  const zoomIntoField = React.useCallback(
    (fieldData) => {
      if (!dimensions) {
        return;
      }
      const maxFieldWidthPercentage = 18; // percent
      const pageWidth = dimensions.width;
      const { x, y, width, height } = toScreenCoords(
        fieldData,
        ORIGIN_VIEWPORT,
      );
      const focus: Focus = {
        x: x + width / 2,
        y: y + height / 2,
        centerVertically: true,
      };
      // Calculate text field width percentage
      const fieldWidthPercentage = (width * 100) / pageWidth;
      if (fieldWidthPercentage < maxFieldWidthPercentage) {
        const newZoom =
          (maxFieldWidthPercentage / fieldWidthPercentage) * numericZoom;
        setZoom(newZoom, focus);
      }
    },
    [dimensions, toScreenCoords, numericZoom, setZoom],
  );

  const scaleZoom = React.useCallback(
    (eventType: 'pinchin' | 'pinchout') => {
      let zoom = numericZoom;

      switch (eventType) {
        case 'pinchin':
          zoom = numericZoom * IN_ZOOM;
          break;
        case 'pinchout':
          zoom = numericZoom * OUT_ZOOM;
          break;
        default:
          // nothing
          break;
      }

      setZoom(zoom);
    },
    [numericZoom, setZoom],
  );

  const prevNumericZoom = usePreviousValue(numericZoom);
  const timerIdX = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const timerIdY = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  React.useLayoutEffect(() => {
    const pageContainer = pageContainerRef.current;
    if (
      pageContainer &&
      prevNumericZoom !== numericZoom &&
      state.zoomAddress &&
      state.zoomFocusBefore
    ) {
      // Figure out how far the zoom focus moved, and scroll it back to where
      // it was.
      const zoomFocusAfter = toScreenCoords(state.zoomAddress, ORIGIN_VIEWPORT);

      const x =
        pageContainer.scrollLeft + zoomFocusAfter.x - state.zoomFocusBefore.x;
      let y = 0;
      if (state.focus && state.focus.centerVertically) {
        const center = getCenter();
        y = pageContainer.scrollTop + zoomFocusAfter.y - center.y;
      } else {
        y =
          pageContainer.scrollTop + zoomFocusAfter.y - state.zoomFocusBefore.y;
      }

      // If the element can't scroll, scrollTop/scrollLeft are set to 0 by the browser (see
      // Element.scrollLeft in MDN). Try to set the value in sync fashion and if that  fails we
      // try one more time in async fashion to wait until the element is resized
      pageContainer.scrollTop = y;
      if (pageContainer.scrollTop !== y) {
        if (timerIdY.current) {
          clearTimeout(timerIdY.current);
        }
        timerIdY.current = setTimeout(() => {
          pageContainer.scrollTop = y;
        }, 0);
      }

      pageContainer.scrollLeft = x;
      if (pageContainer.scrollLeft !== x) {
        if (timerIdX.current) {
          clearTimeout(timerIdX.current);
        }
        timerIdX.current = setTimeout(() => {
          pageContainer.scrollLeft = x;
        }, 0);
      }
    }
  }, [fromScreenCoords, numericZoom, prevNumericZoom, state, toScreenCoords]);

  // When editing a text field we should use the native shortcuts. ctrl+a should
  // select all the text, not select all the fields.
  const areShortcutsDisabled = React.useCallback(() => {
    let tagName;
    if (document.activeElement != null) {
      tagName = document.activeElement.tagName;
    }
    const pageContainer = pageContainerRef.current;
    return (
      tagName === 'TEXTAREA' ||
      tagName === 'INPUT' ||
      (pageContainer && pageContainer.closest('[aria-hidden="true"]'))
    );
  }, [pageContainerRef]);
  const handleKeyDown = React.useCallback(
    (e) => {
      if (areShortcutsDisabled()) {
        return;
      }

      let key = e.key;
      // Safari doesn't support .key, so we have to fall back to .keyCode
      key = key || e.keyCode;

      // metaKey is the Mac's CMD key
      if ((IS_MAC && e.metaKey) || e.ctrlKey) {
        switch (key) {
          case 187:
          case '+':
          case '=': {
            e.preventDefault();
            return setZoom(numericZoom * OUT_ZOOM);
          }
          case 189:
          case '-': {
            e.preventDefault();
            return setZoom(numericZoom * IN_ZOOM);
          }
          case 48:
          case '0': {
            e.preventDefault();
            return setZoom(1);
          }
          default:
        }
      }
    },
    [areShortcutsDisabled, setZoom, numericZoom],
  );

  const handleMouseWheel = React.useCallback(
    (e) => {
      // Prevent changing the Editor's zoom while in preview mode
      if (!pageContainerRef.current) {
        return;
      }
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
        const zoom =
          (e.deltaY || 0 - e.wheelDelta) < 0
            ? numericZoom * OUT_ZOOM
            : numericZoom * IN_ZOOM;
        setZoom(zoom, {
          x: e.clientX,
          y: e.clientY,
          isCenter: false,
        });
      }
    },
    [setZoom, numericZoom],
  );

  useEventListener(window, 'keydown', handleKeyDown);
  useEventListener(window.parent, 'keydown', handleKeyDown);
  const wheelEventName = React.useMemo((): 'wheel' | 'mousewheel' => {
    if ('onwheel' in document.createElement('div')) {
      return 'wheel';
    }
    return 'mousewheel';
  }, []);
  const wheelOptions = React.useMemo(() => ({ passive: false }), []);
  useEventListener(window, wheelEventName, handleMouseWheel, wheelOptions);

  const value = React.useMemo((): SignatureRequestContextShape => {
    return {
      pageContainerRef,
      setPageContainerRef,
      topGap,
      pageGap,
      pages,
      selectedFieldIds,
      documentPreview,
      isOverlay,
      onScroll,
      inlinePreviewMode,
      lastUpdatedFieldId,
    };
    // eslint-disable-next-line max-len
  }, [
    pageGap,
    pages,
    selectedFieldIds,
    setPageContainerRef,
    documentPreview,
    isOverlay,
    topGap,
    onScroll,
    inlinePreviewMode,
    lastUpdatedFieldId,
  ]);

  const zoomContextValue = React.useMemo((): ZoomContextShape => {
    return {
      zoom: numericZoom,
      textScale,
      fitWidthScale,
      pxMaxPageWidth,
      setZoom,
      zoomIntoField,
      scaleZoom,
      toScreenCoords,
      fromScreenCoords,
      yToPageIndex,
      getPageBoundingRect,
    };
    // eslint-disable-next-line max-len
  }, [
    fitWidthScale,
    fromScreenCoords,
    getPageBoundingRect,
    numericZoom,
    pxMaxPageWidth,
    setZoom,
    textScale,
    toScreenCoords,
    yToPageIndex,
    scaleZoom,
    zoomIntoField,
  ]);

  // This is a hack to allow <EditorContext limited access to this context.
  React.useImperativeHandle(
    ref,
    () => ({
      yToPageIndex,
      areShortcutsDisabled,
    }),
    [areShortcutsDisabled, yToPageIndex],
  );

  return (
    <signatureRequestContext.Provider value={value}>
      <fieldsContext.Provider value={fields}>
        <zoomContext.Provider value={zoomContextValue}>
          <PositionContext>
            <ContextCapture>{children}</ContextCapture>
          </PositionContext>
        </zoomContext.Provider>
      </fieldsContext.Provider>
    </signatureRequestContext.Provider>
  );
};

export const SignatureRequestContext = React.forwardRef(
  _SignatureRequestContext,
);
