import React from 'react';
import classnames from 'classnames';
import { Field } from 'signer-app/types/editor-types';
import {
  Origin,
  signatureRequestContext,
  zoomContext,
  ZoomContextShape,
} from 'signer-app/signature-request/context';
import { useSetFieldTooltipRef } from 'signer-app/signature-request/field-tooltip';
import { ORIGIN_PAGE } from 'signer-app/signature-request/constants';
import { positionContext } from 'signer-app/signature-request/position-context';
import DisplayField, {
  FieldContextProvider,
  WrapperStyles,
} from 'signer-app/signature-request/display-field';

import styles from 'signer-app/signature-request/handle.module.css';

type Props = {
  // The way I built this is super awkward. Signer and Overlay use DisplayField,
  // but the Editor uses a different component. I don't see a good way to make
  // the types cooperate here.
  Field: React.ComponentType<any>;
  fieldData: Field;
  origin?: Origin;
};

type ContextData = {
  isSelected: boolean;
  textScale: ZoomContextShape['textScale'];
  documentPreview: boolean;
  inlinePreviewMode: boolean;
  lastUpdatedFieldId?: Field['id'];
  x: string | number;
  y: string | number;
  width: string | number;
  height: string | number;
};

function Handle({
  Field = DisplayField,
  fieldData,
  isSelected,
  textScale,
  documentPreview,
  inlinePreviewMode,
  lastUpdatedFieldId,
  x,
  y,
  width,
  height,
}: Props & ContextData) {
  const rootSignerClass = `signer-${fieldData.signer}`;
  const signerClass = `bd-${rootSignerClass}`;

  const elementRef = React.useRef<HTMLDivElement>(null);
  useSetFieldTooltipRef(fieldData.id, elementRef);
  if (inlinePreviewMode && lastUpdatedFieldId === fieldData.id) {
    if (elementRef.current?.scrollIntoView) {
      elementRef.current.scrollIntoView();
    }
  }

  const handleStyle = {
    left: x,
    top: y,
    width,
    height,
  };

  const wrapperStyles = React.useMemo(
    (): WrapperStyles => ({
      position: 'absolute',
      height: `${100 / textScale}%`,
      width: `${100 / textScale}%`,
      transformOrigin: 'top left',
      transform: `scale(${textScale})`,
      // Safari requires the WebKit prefix
      WebkitTransformOrigin: 'top left',
      WebkitTransform: `scale(${textScale})`,
    }),
    [textScale],
  );

  return (
    <div
      ref={elementRef}
      data-field={fieldData.name || fieldData.id}
      data-qa-ref={`field-${fieldData.type}`}
      data-testid={`field-${fieldData.type}`}
      data-signer={fieldData.signer}
      className={classnames(styles.handle, styles[`${signerClass}`], {
        [styles.selected]: isSelected,
        [styles.documentPreview]: documentPreview,
        // This class adds pointer-events: none so we need to exclude hyperlinks to make them work
        [styles.readOnly]: fieldData.readOnly && fieldData.type !== 'hyperlink',
      })}
      style={handleStyle}
      data-field-data={
        typeof jest === 'undefined' ? undefined : JSON.stringify(fieldData)
      }
    >
      <FieldContextProvider fieldData={fieldData} wrapperStyles={wrapperStyles}>
        <Field
          fieldData={fieldData}
          textScale={textScale}
          disabled={documentPreview && fieldData.signer === 'preparer'}
          height={wrapperStyles.height}
          width={wrapperStyles.width}
        />
      </FieldContextProvider>
    </div>
  );
}

const MemoHandle = React.memo(Handle);

export default function HandleContext(props: Props) {
  const {
    selectedFieldIds,
    documentPreview,
    inlinePreviewMode,
    lastUpdatedFieldId,
  } = React.useContext(signatureRequestContext);
  const { textScale } = React.useContext(zoomContext);
  const { toScreenCoords } = React.useContext(positionContext);
  const { fieldData, origin = ORIGIN_PAGE } = props;
  const { x, y, width, height } = React.useMemo(
    () => toScreenCoords(fieldData, origin),
    [fieldData, origin, toScreenCoords],
  );

  return (
    <MemoHandle
      {...props}
      lastUpdatedFieldId={lastUpdatedFieldId}
      isSelected={selectedFieldIds.includes(props.fieldData.id)}
      textScale={textScale}
      x={x}
      y={y}
      width={width}
      height={height}
      documentPreview={documentPreview}
      inlinePreviewMode={inlinePreviewMode}
    />
  );
}
