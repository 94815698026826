/* eslint-disable @typescript-eslint/no-use-before-define */
import SignatureProviderImpl from 'signer-app/signature-modal/signature-modal-context/signature-provider-impl';
import React from 'react';
import SignerDocument from 'signer-app/signer-signature-document/document';
import SignerContext, {
  signerContext,
} from 'signer-app/signer-signature-document/context';
import { SignerContextShape } from 'signer-app/signer-signature-document/types';
import { Field } from 'signer-app/types/editor-types';
import TouchProvider from 'signer-app/parts/touch-provider';
import { useValidation } from 'signer-app/signer-experience/use-validation';
import { ValidationComponent } from 'signer-app/signer-experience/validation-component';
import styles from 'signer-app/signer-experience/signer-experience.module.css';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import invariant from 'invariant';
import { SignerToolbar } from 'signer-app/signer-experience/signer-toolbar';
import { ReviewToolbar } from 'signer-app/signer-experience/review-toolbar';
import { useIsMobile } from 'signer-app/utils/use-match-media';
import { useAutoSaveSignerExperience } from 'signer-app/signer-experience/use-auto-save-signer-experience';
import { AccessCode } from 'signer-app/signer-experience/access-code';
import { useSetAtom } from 'jotai';
import { footerAtom } from 'signer-app/signer-experience/footer';
import { AttachmentsPage } from 'signer-app/signer-experience/attachments-page';

type SignerAppParams = {
  signatureRequestId: string;
  step: 'accessCode' | 'preview' | 'sign' | 'attachments' | 'review';
  fieldId: string | null;
};
export const useSignerAppParams = () => {
  const params = useParams<any>();
  return {
    signatureRequestId: params.signatureRequestId ?? '',
    step: params.step ?? 'preview',
    fieldId: params.fieldId ?? null,
  } as SignerAppParams; // I'm casting this because technically step could be any string
};

export const useSignerContext = (): SignerContextShape =>
  React.useContext(signerContext);

export { SignerDocument };

export const SignatureWorkflow: React.FC<object> = () => {
  const { step } = useSignerAppParams();

  let toolbar;
  switch (step) {
    case 'accessCode':
      toolbar = <></>;
      break;
    case 'review':
      toolbar = <ReviewToolbar legalVersion={2} />;
      break;
    case 'attachments': {
      return (
        <>
          <SignerToolbar />
          <AttachmentsPage />
        </>
      );
    }

    case 'preview':
    case 'sign':
    default:
      toolbar = <SignerToolbar />;
      break;
  }

  return (
    <>
      {toolbar}

      <div className={styles.documentContainer}>
        <ValidationComponent />
        <SignerDocument />
      </div>
    </>
  );
};

export const SignerExperience: React.FC<
  React.PropsWithChildren<{
    privacyURL: string;
    termsURL: string;
  }>
> = ({ children, privacyURL, termsURL }) => {
  const setFooterURLs = useSetAtom(footerAtom);
  React.useEffect(() => {
    setFooterURLs({ privacyURL, termsURL });
  }, [privacyURL, setFooterURLs, termsURL]);

  const { signatureRequestId, fieldId, step } = useSignerAppParams();
  invariant(signatureRequestId, 'signatureRequestId is required');

  const match = useRouteMatch<{ baseUrl: string }>('/:baseUrl');
  const baseUrl = match?.params?.baseUrl ?? 'sign';
  const history = useHistory();
  const setCurrentFieldId = (id: Field['id'] | null) => {
    if (step === 'preview') {
      history.push(`/${baseUrl}/${signatureRequestId}/sign/${id || ''}`);
      return;
    }
    history.push(`/${baseUrl}/${signatureRequestId}/${step}/${id || ''}`);
  };
  // console.log(`/sign/${signatureRequestId}/${step}/${currentFieldId}`);

  const selectedFieldIds = React.useMemo(() => {
    return fieldId ? [fieldId] : [];
  }, [fieldId]);

  const isMobile = useIsMobile();
  const isDropbox = true;

  const {
    onFieldUpdate,
    data,
    signatureRequest,
    isFetching,
    setAccessCode,
    uploadAttachment,
  } = useAutoSaveSignerExperience(signatureRequestId);
  const validationErrors = useValidation(signatureRequest, fieldId);

  if (typeof data === 'string') {
    return (
      <AccessCode
        error={data}
        isFetching={isFetching}
        onSubmit={setAccessCode}
      />
    );
  }
  if (isFetching || !data || !signatureRequest) {
    return <div>Loading...</div>;
  }

  const {
    user,
    isEmbedded,
    canInsertEverywhere,
    allowColorSignature,
    defaultDateFormat,
  } = data;

  return (
    <SignatureProviderImpl
      hideSpinner={true}
      isMobile={isMobile}
      isDropbox={isDropbox}
      firstName={user.settings?.firstName ?? ''}
      lastName={user.settings?.lastName ?? ''}
      primarySignatureGuid={user.primarySignatureGuid}
      isEmbedded={isEmbedded}
      canInsertEverywhere={canInsertEverywhere}
      allowColorSig={allowColorSignature}
    >
      <TouchProvider>
        <SignerContext
          startEnabled={true}
          selectedFieldIds={selectedFieldIds}
          onFieldUpdate={onFieldUpdate}
          signatureRequest={signatureRequest}
          uploadAttachment={uploadAttachment}
          setCurrentField={setCurrentFieldId}
          validationErrors={validationErrors}
          defaultDateFormat={defaultDateFormat}
        >
          {children}
        </SignerContext>
      </TouchProvider>
    </SignatureProviderImpl>
  );
};
