/* If you edit this file, please remove this header and clean up the resulting eslint errors.
 */
/* eslint-disable
  global-require,
  no-fallthrough
*/
import './index.scss';
import React, { useMemo } from 'react';
import errorCodes from 'signer/api/errors';
import ErrorBoundary from 'signer-app/parts/error-boundary';
import logger from 'common/logger';
import createReactClass from 'create-react-class';
import TypeNotifier from 'common/notifiers/type';
import { ERROR } from 'common/messages';
import AppContext from 'hello-react/web-app-client/build-web-app-client';
import { BrowserRouter } from 'react-router-dom';
import { CONSTANTS } from 'signer-app/signature-modal/modal';
import SignatureProviderImpl from 'signer-app/signature-modal/signature-modal-context/signature-provider-impl';
import { defineMessages } from 'react-intl';
import TouchProvider from 'signer-app/parts/touch-provider';
import { SignerUserProvider } from 'signer/components/signer-user';
import { DefaultLayoutProvider } from 'hellosign/common/layout/context';
import { NotificationBannerProvider } from 'signer-app/parts/notifications/notification-banners';
import { useNotificationBanners } from 'signer-app/parts/notifications/notification-banner-context';
import { DebugToolbar } from 'hello-react/common/debug-toolbar';
import Pages from './pages';
import ErrorPage from './pages/error-page';
import SignerChoice from './pages/signer-choice';
import TemplateLink from './pages/template-link';
import SignatureRequestStatus from './pages/signature-request-status';
import FullScreenModals from './full-screen-modals';

const AccessCode = React.lazy(() => import('./pages/access-code'));
const LoginModal = React.lazy(
  () => import('hellosign/components/auth/login-modal'),
);
const SmsAuthNumber = React.lazy(() => import('./pages/sms-auth-number'));

const messages = defineMessages({
  additionalRequiredFields: {
    id: 'signer.document.alert.additionalRequiredFields',
    description:
      'notification message in singer flow, shows when the back requires additional fields to be filled out',
    defaultMessage:
      '{count} additional { count, plural, =1 {field is} other {fields are} } required',
  },
});

const Main = createReactClass({
  mixins: [require('common/components/mixins/watch-app-notifier')],

  //  ----  INITIALIZATION  -----------------------------

  getInitialState() {
    let error = this.props.error;
    const signatureRequest = this.props.app.signatureRequest;

    // TODO - refactor location check to location.state.contentPage === 'complete' check. This needs
    // to be tested before changing (CC)
    if (
      !error &&
      signatureRequest.isSigned &&
      !this.props.app.location.pathname.match(/\/complete/)
    ) {
      error = {
        code: errorCodes.NOTHING_TO_SIGN,
      };
    }

    return {
      isOffline: error && error.code === errorCodes.NETWORK,
      error,
    };
  },

  //  ----  LIFECYCLE  ----------------------------------

  componentDidMount() {
    this.props.app.notifier.push(
      (this._errorNotifier = TypeNotifier.create(ERROR, this.onError)),
    );
  },

  componentWillUnmount() {
    this.props.app.notifier.remove(this._errorNotifier);
  },

  //  ----  BEHAVIOR  -----------------------------------

  onError(message) {
    const error = message.error;
    logger.track('Main.onError', {
      error,
    });
    logger.error(error);

    if (error) {
      let isOffline = error.code === errorCodes.NETWORK;
      if (isOffline && this.state.isOffline) {
        // No need to re-render in this case
        return;
      } else if (!isOffline && this.state.isOffline) {
        // Connectivity was recovered
        isOffline = false;
      }

      this.setState({
        isOffline,
        error,
      });
    }
  },

  onNetworkRecovery() {
    this.setState({
      isOffline: false,
      error: null,
    });
  },

  onValidAccessCode() {
    this.setState({
      error: null,
    });
  },

  onSignerSelection(selectedSigner, tsmGuid) {
    // Reset error
    this.setState({
      error: null,
    });

    // Trigger load() with the right selected signer info
    const signatureRequest = this.props.app.signatureRequest;
    signatureRequest.selectSigner(selectedSigner, tsmGuid);
  },

  //  ----  RENDERING  ----------------------------------

  getPlatformClass() {
    return `is-${this.props.app.platform}`;
  },

  renderLoginForm() {
    return (
      <LoginModal
        tabOffset={100}
        header="Welcome back"
        loginUrl="/account/logIn"
        googleSignInClientId={this.props.login.googleSignInClientId}
        recoverPasswordForm={this.props.login.recoverPasswordForm}
        loginForm={this.props.login.loginForm}
        redirectUrl={this.props.login.redirectUrl}
        arkoseEnabled={this.props.login.arkoseEnabled}
        arkosePublicKey={this.props.login.arkosePublicKey}
      />
    );
  },

  renderErrorPage(classes) {
    let content = null;
    const error = this.state.error;
    let numAttemptsLeft = null;
    let rateLimitExceed = null;
    const signatureRequest = this.props.app.signatureRequest;
    let pollCallback = null;
    const isTemplateLink = !!this.props.app.templateLink;

    switch (error.code) {
      case errorCodes.CREDENTIALS:
        // Template link login / signup
        if (isTemplateLink) {
          content = <TemplateLink {...this.props} error={this.state.error} />;
          // Login form
        } else {
          content = this.renderLoginForm();
        }
        break;

      case errorCodes.VERIFY_EMAIL:
        content = <TemplateLink {...this.props} error={this.state.error} />;
        break;

      case errorCodes.SIGNER_CHOICE:
        // Signer choice form
        content = (
          <SignerChoice
            {...this.props}
            error={this.state.error}
            emailAddress={error.data.email}
            signers={error.data.signers}
            selectionCallback={this.onSignerSelection}
          />
        );
        break;

      case errorCodes.SMS_AUTH_INVALID:
        numAttemptsLeft = error.data.numAttemptsLeft;

      case errorCodes.SMS_AUTH_BLOCKED:
        numAttemptsLeft = 0;

      case errorCodes.SMS_RATE_LIMIT:
        rateLimitExceed = error.data.rateLimitExceed;

      case errorCodes.SMS_AUTH_REQUIRED:
        // Enter code form
        classes.push('is-loading is-access-locked');

        content = (
          <SmsAuthNumber
            {...this.props}
            error={this.state.error}
            signatureRequest={signatureRequest}
            numAttemptsLeft={numAttemptsLeft !== null ? numAttemptsLeft : null}
            rateLimitExceed={rateLimitExceed !== null ? rateLimitExceed : null}
            signerNumber={
              error.data.signerNumber ? error.data.signerNumber : null
            }
            requesterName={
              error.data.requesterName ? error.data.requesterName : null
            }
            requesterEmail={
              error.data.requesterEmail ? error.data.requesterEmail : null
            }
            callback={this.onValidAccessCode}
          />
        );
        break;

      case errorCodes.ACCESS_CODE_INVALID:
        // Enter code form with error message
        numAttemptsLeft = error.data.numAttemptsLeft;

      case errorCodes.ACCESS_CODE_BLOCKED:
        // Enter code form with error message
        numAttemptsLeft = 0;

      case errorCodes.ACCESS_CODE_REQUIRED:
        // Enter code form
        classes.push('is-loading is-access-locked');
        content = (
          <AccessCode
            {...this.props}
            error={this.state.error}
            signatureRequest={signatureRequest}
            numAttemptsLeft={numAttemptsLeft !== null ? numAttemptsLeft : null}
            callback={this.onValidAccessCode}
          />
        );
        break;

      case errorCodes.NOTHING_TO_SIGN:
        // NothingToSign view or redirect based on platform
        if (this.props.app.isDesktop && !this.props.app.isEmbedded()) {
          // FIXME: This shouldn't be done here
          window.location = '/info/externalNothingToSign';
        } else {
          content = (
            <ErrorPage
              code={error.code}
              iconName="checkmark"
              message="error.nothingToSign.message"
            />
          );
        }
        break;

      case errorCodes.NOT_FOUND:
        // Custom error page
        content = (
          <ErrorPage code={error.code} message="error.notFound.message" />
        );
        break;

      case errorCodes.NETWORK:
        // Custom error page
        pollCallback = this.onNetworkRecovery;

      case errorCodes.NO_VALID_SIG_TYPES:
        // Custom error page
        content = (
          <div>
            {this.props.app.isMobile ? (
              <ErrorPage
                {...this.props}
                code={error.code}
                pollCallback={pollCallback}
                error={this.state.error}
              />
            ) : null}
            <Pages {...this.props} error={this.state.error} />
          </div>
        );
        break;
      case errorCodes.FIELD_VALIDATION: {
        const signatureRequest = this.props.location.state.signatureRequest;
        const errorFields = Object.keys(error.meta.fields);
        const guid = errorFields[0];
        this.setState({ error: null });
        errorFields.forEach((guid) => {
          const field = signatureRequest.getFieldByGuid(guid);
          // we're marking the field as required since the backend has decided it is
          field.required = true;
        });
        const message = this.props.intl.formatMessage(
          messages.additionalRequiredFields,
          { count: errorFields.length },
        );
        this.props.sendNotification({
          autoClose: false,
          withCloseButton: true,
          animate: false,
          message,
          type: 'error',
        });
        window.location = `${window.location.pathname}#/sign/${guid}`;
        break;
      }
      case errorCodes.SENDER_IS_EDITING:
        window.location = '/info/externalCantSignNow';
        break;
      default:
        // Generic error page
        throw new Error(`code: ${error.code} message: ${error.message}`);
    }

    return content;
  },

  render() {
    let content;
    const signatureRequest = this.props.location.state.signatureRequest;
    const classes = [
      this.getPlatformClass(),
      this.props.app.isEmbedded() ? 'is-embedded' : 'is-site-ui',
    ];

    if (this.state.error) {
      // Render error page
      content = this.renderErrorPage(classes);
    } else if (this.props.app.isPendingQualifiedSignature) {
      const { router } = this.props.app;
      router.displayFullScreenModal('completeQualifiedSignature');
    } else if (!signatureRequest.isReady()) {
      // Render loading screen
      classes.push('is-loading');

      // just return the signature request status page here. It's a centered
      // loader which shouldn't have any padding around it (CC).
      return (
        <SignatureRequestStatus {...this.props} error={this.state.error} />
      );
    } else if (signatureRequest.isSigned) {
      window.history.forward();
    }
    if (!content) {
      // Render signature request
      classes.push('is-loaded whitelabel-page');
      content = <Pages {...this.props} error={this.state.error} />;
    }

    const modal = <FullScreenModals {...this.props} error={this.state.error} />;

    const legacyApp = this.props.app;
    // This data never changes, so I'm caching the object so it doesn't trigger
    // re-renders. SignatureProviderImpl is a PureComponent.
    this.cachedSignatureHack = this.cachedSignatureHack || {
      type: CONSTANTS.TYPE_SIGNATURE,
    };

    const { user } = legacyApp;
    return (
      <SignatureProviderImpl
        // SignatureProviderImpl makes a network request when it mounts, so
        // it's best to mount it as high in the React tree as we can. Mounting
        // this here means it doesn't have to re-fetch when moving between the
        // Preview, Signing, and Confirm pages.
        hideSpinner={true}
        signature={this.cachedSignatureHack}
        csrfToken={legacyApp.csrfToken}
        isMobile={legacyApp.isMobile}
        firstName={user.settings.firstName}
        lastName={user.settings.lastName}
        primarySignatureGuid={user.primarySignatureGuid}
        defaultSignatureType={signatureRequest.settings.defaultSignatureType}
        allowedSignatureTypes={signatureRequest.settings.allowedSignatureTypes}
        isEmbedded={this.props.app.isEmbedded()}
        canInsertEverywhere={this.props.app.canInsertEverywhere()}
        allowColorSig={signatureRequest.allowColorSignature()}
      >
        <React.Suspense fallback={null}>
          <div className={classes.join(' ')}>
            <div className="m-signer-mobile">{content}</div>
            {modal}
          </div>
          {NODE_ENV === 'development' && <DebugToolbar />}
        </React.Suspense>
      </SignatureProviderImpl>
    );
  },
});

function MainPage(props) {
  const { sendNotification } = useNotificationBanners();
  return <Main {...props} sendNotification={sendNotification} />;
}

/**
 * <PageWithContext is kind of the signer bundle's version of `<HelloSPA`. I
 * think the best way forward in the signer app is gong to be to rewrite
 * components and simply not bring along `Model` and `Application`. With that in
 * mind, I think we should try making a new `<SignerSPA` bundle based on
 * `<HelloReactApplication` and copy/refactor things over to there.
 *
 *
 * @AppExplorer https://miro.com/app/board/uXjVPXskloA=/?moveToWidget=3458764535232435408&cot=14
 */
export function PageWithContext(props) {
  const defaultLayoutContextValues = {
    privacyURL: props.privacyPolicyUrl,
    termsURL: props.tosUrl,
  };
  const cachedUserHack = useMemo(
    () => ({
      settings: {
        firstName: props.app.user.settings.firstName,
        lastName: props.app.user.settings.lastName,
      },
    }),
    [props.app.user.settings],
  );
  return (
    <AppContext
      csrfToken={props.app.csrfToken}
      appActions={props.app.appActions}
    >
      <DefaultLayoutProvider value={defaultLayoutContextValues}>
        <SignerUserProvider value={cachedUserHack}>
          <BrowserRouter>
            <NotificationBannerProvider>
              <TouchProvider>
                <MainPage {...props} />
              </TouchProvider>
            </NotificationBannerProvider>
          </BrowserRouter>
        </SignerUserProvider>
      </DefaultLayoutProvider>
    </AppContext>
  );
}

export default function PageWithErrorBoundary(props) {
  return (
    <ErrorBoundary>
      <PageWithContext {...props} />
    </ErrorBoundary>
  );
}
