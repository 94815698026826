import { Button, IconButton } from '@dropbox/dig-components/buttons';
import {
  FormRow,
  FormLabel,
  FormHelperText,
} from '@dropbox/dig-components/form_row';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Card } from '@dropbox/dig-components/card';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { ShowLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { WordmarkLogo, SignWordmark } from '@dropbox/dig-logos';
import React from 'react';
import styles from 'signer-app/signer-experience/access-code.module.css';
import { AccessCodeResponse } from 'signer-app/signer-experience/use-auto-save-signer-experience';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Footer } from 'signer-app/signer-experience/footer';

const messages = defineMessages({
  invalidCode: {
    id: '1a40b9244fd10d1fd8905a51fd7ca4362742bc6a954c4b2ec2237cc8860ae183',
    defaultMessage: 'Invalid access code',
    description: 'Error message for invalid access code',
  },
  tooManyRetries: {
    id: '1ed1583bb2c5f7d656cff14904c7c60833b74ed42d9b0bceb389d3eeca0383a5',
    defaultMessage: 'Too many retries',
    description: 'Error message for too many retries submitting an access code',
  },
  hidePassword: {
    id: '6ea66a547ea83487afe68029a4c104028f152ed70de7e9fdf9dc8ee5b07d10e5',
    defaultMessage: 'Hide password',
    description: 'Aria label for hide password button',
  },
  showPassword: {
    id: 'b8cce5c9e128f7d355e4aab2b08c32b535f7146bf6709166afdcccfd3d3fcd7d',
    defaultMessage: 'Show password',
    description: 'Aria label for show password button',
  },
});

export function AccessCode(props: {
  error: AccessCodeResponse | null;
  isFetching: boolean;
  onSubmit: (accessCode: string) => void;
}) {
  const intl = useIntl();
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit(e.currentTarget.accessCode.value);
  };
  const [viewPass, toggleViewPass] = React.useReducer((x) => !x, false);
  const showError = React.useMemo(() => {
    switch (props.error) {
      case 'need-access-code':
        return null;
      case 'invalid-access-code':
        return intl.formatMessage(messages.invalidCode);
      case 'too-many-retries':
        return intl.formatMessage(messages.tooManyRetries);
      default:
        return null;
    }
  }, [intl, props.error]);

  return (
    <form onSubmit={onSubmit}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        className={styles.authCode}
      >
        <Box className={styles.header} display="flex" flexDirection="row">
          <WordmarkLogo size={56} src={SignWordmark} />
          <Box className={styles.whitespace}>
            <Box className={styles.whitespaceExpander} />
          </Box>
        </Box>
        <Box marginTop="Macro Medium" padding={'Macro Medium'} margin="auto">
          <Card>
            <Card.Content spacing="large">
              <FormRow>
                <FormLabel htmlFor="accessCode">
                  <FormattedMessage
                    id="e108786ae6fc15389e260580fecf0d853022eac8ebac29ce175290a09f4d033f"
                    defaultMessage="Please enter an access code to view"
                    description="Label for access code input"
                  />
                </FormLabel>
                <TextInput
                  name="accessCode"
                  id="accessCode"
                  aria-describedby="case-sensitive"
                  disabled={
                    props.isFetching || props.error === 'too-many-retries'
                  }
                  type={viewPass ? 'text' : 'password'}
                  withRightAccessory={
                    <IconButton
                      variant="transparent"
                      size="small"
                      onClick={toggleViewPass}
                      type="button"
                    >
                      <UIIcon
                        size="small"
                        src={ShowLine}
                        aria-label={intl.formatMessage(
                          viewPass
                            ? messages.hidePassword
                            : messages.showPassword,
                        )}
                      />
                    </IconButton>
                  }
                />
                <FormHelperText id="case-sensitive">
                  <FormattedMessage
                    id="3faaffc87af01cbd1155a71ac53f0a14d0ea5ca273978c957dc8cb27596b3c72"
                    defaultMessage="Note: the access code is case sensitive."
                    description="Helper text for access code input"
                  />
                </FormHelperText>
                {showError && (
                  <FormHelperText id="error" variant="alert">
                    {showError}
                  </FormHelperText>
                )}
              </FormRow>
              <Button
                type="submit"
                variant="primary"
                disabled={
                  props.isFetching || props.error === 'too-many-retries'
                }
              >
                <FormattedMessage
                  id="6a02f9921e0e52b306d9a720872dbc94a46d05a32ddff441040953abdd1bddcf"
                  defaultMessage="Continue"
                  description="Button text for submitting access code"
                />
              </Button>
            </Card.Content>
          </Card>
        </Box>
      </Box>
      <Footer />
    </form>
  );
}
