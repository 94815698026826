import React, { FormEventHandler, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from '@tanstack/react-query';
import { Button } from '@dropbox/dig-components/buttons';
import {
  FormHelperText,
  FormRow,
  FormLabel,
} from '@dropbox/dig-components/form_row';
import { Modal, ModalProps } from '@dropbox/dig-components/modal';
import { TextArea } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';

import {
  SignerAppClient,
  useSignerAppClient,
} from 'signer-app/context/signer-app-client';
import { signerDeclineModalMessages } from 'signer-app/signer-decline-modal/signer-decline-modal.intl';
import { onEmbeddedSignerDeclineSuccess } from 'signer-app/signer-decline-modal/signer-decline-modal.utils';
import styles from 'signer-app/signer-decline-modal/signer-decline-modal.module.css';

type Props = {
  isEmbedded?: boolean;
  transmissionGuid: string;
  parentUrl?: string;
  onRequestClose: ModalProps['onRequestClose'];
  onDeclineSuccess: (
    response: Awaited<
      ReturnType<
        SignerAppClient['signerApi']['signerAppSignatureRequestDecline']
      >
    >,
  ) => void;

  isComingFromSMSDelivery?: boolean;

  /**
   * The access code provided by the signer
   */
  accessCode?: string | null;

  /**
   * The key of the currently selected signer in the scenario where a signer
   * needed to choose their identity before signing.
   */
  signerKey?: string | null;

  /**
   * The signature ID used for embedded signing
   */
  signatureId?: string | null;
};

export function SignerDeclineModal({
  onRequestClose,
  onDeclineSuccess,
  transmissionGuid,
  accessCode,
  signerKey,
  isComingFromSMSDelivery,
  isEmbedded,
  parentUrl,
  signatureId,
}: Props): JSX.Element {
  const { signerApi } = useSignerAppClient();

  const intl = useIntl();
  const [formData, setFormData] = useState({
    declineReason: '',
  });
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    },
    [formData, setFormData],
  );

  const trimmedReason = useMemo(
    () => formData.declineReason.trim(),
    [formData.declineReason],
  );

  const mutation = useMutation({
    mutationFn: () => {
      return signerApi
        .signerAppSignatureRequestDecline({
          transmissionGuid,
          reason: trimmedReason,
          accessCode,
          signerKey,
          isComingFromSMSDelivery,
        })
        .catch((e) => {
          throw e;
        });
    },
    onSuccess(response) {
      if (isEmbedded && parentUrl && signatureId) {
        onEmbeddedSignerDeclineSuccess({
          signatureId,
          declineReason: trimmedReason,
          parentUrl,
        });
      }

      onDeclineSuccess(response);
    },
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      mutation.mutate();
    },
    [mutation],
  );

  return (
    <Modal
      open
      isCentered
      withCloseButton={intl.formatMessage(signerDeclineModalMessages.close)}
      onRequestClose={onRequestClose}
      aria-labelledby="signer-decline-modal-title"
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header hasBottomSpacing="title-standard">
          <Modal.Title id="signer-decline-modal-title">
            <FormattedMessage {...signerDeclineModalMessages.title} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text tagName="p" variant="paragraph">
            <FormattedMessage {...signerDeclineModalMessages.description} />
          </Text>
          <FormRow>
            <FormLabel htmlFor="declineReason">
              <FormattedMessage {...signerDeclineModalMessages.reasonTitle} />
              &nbsp;
              <span className={styles.alert}>*</span>
            </FormLabel>
            <TextArea
              autoFocus
              id="declineReason"
              aria-describedby="decline-reason-ps"
              data-testid="textarea-decline-reason"
              disabled={mutation.isLoading}
              onChange={handleChange}
              name="declineReason"
              value={formData.declineReason}
            />
            <FormHelperText id="decline-reason-ps">
              <FormattedMessage
                {...signerDeclineModalMessages.sharedOnlyWithSender}
              />
            </FormHelperText>
          </FormRow>
          {mutation.isError && (
            <FormHelperText id="decline-reason-error" variant="alert">
              <FormattedMessage {...signerDeclineModalMessages.declineFailed} />
            </FormHelperText>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onRequestClose} type="button" variant="opacity">
            <FormattedMessage {...signerDeclineModalMessages.cancelButton} />
          </Button>
          <Button
            data-test-id="signer-decline-modal-submit"
            disabled={formData.declineReason.length === 0}
            isLoading={mutation.isLoading}
            type="submit"
            variant="primary"
          >
            <FormattedMessage {...signerDeclineModalMessages.declineButton} />
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
