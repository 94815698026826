import React from 'react';
import { Button } from '@dropbox/dig-components/buttons';
import {
  useSignatureRequestContext,
  useFieldsContext,
} from 'signer-app/signature-request/context';
import {
  useSignerAppParams,
  useSignerContext,
} from 'signer-app/signer-experience/signer-experience';
import { FormattedMessage } from 'react-intl';
import styles from 'signer-app/signer-experience/next-button.module.css';
import { useHistory, useRouteMatch } from 'react-router';

export const NextButton: React.FC<object> = () => {
  const { selectedFieldIds } = useSignatureRequestContext();
  const { step } = useSignerAppParams();
  const { selectField, requiredFields, attachmentSlots } = useSignerContext();
  const [activeField] = selectedFieldIds;
  const fields = useFieldsContext();
  const match = useRouteMatch<{ baseUrl: string; signatureRequestId: string }>(
    '/:baseUrl/:signatureRequestId',
  );
  const { baseUrl, signatureRequestId } = match!.params;
  const history = useHistory();
  const handleNext = () => {
    if (step === 'attachments') {
      history.push(`/${baseUrl}/${signatureRequestId}/review/`);
    } else if (step === 'sign' && requiredFields === 0) {
      if (attachmentSlots.length > 0) {
        history.push(`/${baseUrl}/${signatureRequestId}/attachments/`);
      } else {
        history.push(`/${baseUrl}/${signatureRequestId}/review/`);
      }
    } else if (!activeField) {
      const field = fields[0];
      selectField(field.id);
    } else {
      const i = fields.findIndex((f) => f.id === activeField);
      const field = fields[(i + 1) % fields.length];
      selectField(field.id);
    }
  };

  return (
    <Button
      className={styles.nextButton}
      variant="primary"
      disabled={
        step === 'attachments' &&
        attachmentSlots.some((a) => a.required && !a.uploaded_at)
      }
      onClick={handleNext}
    >
      {step === 'preview' && (
        <FormattedMessage
          id="signer.preview.getStartedButton.labelText"
          defaultMessage="Get started"
          description="Button text in signer flow, when clicked goes to next page to fill input fields and sign"
        />
      )}
      {step === 'sign' && requiredFields > 0 && (
        <FormattedMessage
          id="e68dfb13e9194b0ab2b9282009d009eb9089ad52d6ce994f0f12fba939b0d050"
          defaultMessage="Next"
          description="Next button in the signer experience."
        />
      )}
      {step === 'sign' && requiredFields === 0 && (
        <FormattedMessage
          id="88555b111c0c2bcc4ac26c6cac92b51bc4ac747dff398cf96777fa2b10e11c48"
          defaultMessage="Continue"
          description="Next button in the signer experience."
        />
      )}
      {step === 'attachments' && (
        <FormattedMessage
          id="e68dfb13e9194b0ab2b9282009d009eb9089ad52d6ce994f0f12fba939b0d050"
          defaultMessage="Next"
          description="Next button in the signer experience."
        />
      )}
      {step === 'review' && (
        <FormattedMessage
          id="baa002335fe313938d34e2258f01d304e8efa2b6527201dd981b148922a8ec94"
          defaultMessage="I Agree"
          description="This button completes the signer experience"
        />
      )}
    </Button>
  );
};
